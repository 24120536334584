import React from "react";
import { InlineIcon as IconifyIcon } from "@iconify/react";
import isiJavascript from "@iconify/icons-simple-icons/javascript";
import isiTypescript from "@iconify/icons-simple-icons/typescript";
import isiReact from "@iconify/icons-simple-icons/react";
import isiRedux from "@iconify/icons-simple-icons/redux";
import isiCSS from "@iconify/icons-simple-icons/css3";
import isiTailwind from "@iconify/icons-simple-icons/tailwindcss";
import isiNode from "@iconify/icons-simple-icons/node-dot-js";
import isiNext from "@iconify/icons-simple-icons/next-dot-js";
import isiElectron from "@iconify/icons-simple-icons/electron";
import isiGraphQl from "@iconify/icons-simple-icons/graphql";
import isiApolloGraphQl from "@iconify/icons-simple-icons/apollographql";
import isiDgraph from "@iconify/icons-simple-icons/dgraph";
import isiVercel from "@iconify/icons-simple-icons/vercel";
import isiFirebase from "@iconify/icons-simple-icons/firebase";
import isiFigma from "@iconify/icons-simple-icons/figma";
import isiGit from "@iconify/icons-simple-icons/git";

export default {
  firstname: "Edward",
  them: "him",
  name: "Edward Mowle",
  path: "/team/edward",
  email: "edward@codia.co.uk",
  title: "Software Consultant",
  subtitle: "Director",
  icons: [
    (props) => <IconifyIcon icon={isiJavascript} {...props} />,
    (props) => <IconifyIcon icon={isiTypescript} {...props} />,
    (props) => <IconifyIcon icon={isiReact} {...props} />,
    (props) => <IconifyIcon icon={isiRedux} {...props} />,
    (props) => <IconifyIcon icon={isiCSS} {...props} />,
    (props) => <IconifyIcon icon={isiTailwind} {...props} />,
    (props) => <IconifyIcon icon={isiNode} {...props} />,
    (props) => <IconifyIcon icon={isiNext} {...props} />,
    (props) => <IconifyIcon icon={isiElectron} {...props} />,
    (props) => <IconifyIcon icon={isiGraphQl} {...props} />,
    (props) => <IconifyIcon icon={isiApolloGraphQl} {...props} />,
    (props) => <IconifyIcon icon={isiDgraph} {...props} />,
    (props) => <IconifyIcon icon={isiVercel} {...props} />,
    (props) => <IconifyIcon icon={isiFirebase} {...props} />,
    (props) => <IconifyIcon icon={isiFigma} {...props} />,
    (props) => <IconifyIcon icon={isiGit} {...props} />,
  ],
  available: "June 2021",
  linkedin: "linkedin.com/in/edwardmowle",
  cv: "/cv/codia_software_cv_edward_mowle.pdf",
  about: [
    {
      maxWidth: "17cm",
      text: "I'm an experienced software engineer specialising in full-stack JavaScript app development (though I most enjoy working on the front-end). The key technologies that I work with day to day are React, CSS, GraphQL, Apollo Server/Client, and NodeJS (though I used plenty of others over the years).",
    },
    {
      maxWidth: "15.5cm",
      text: "I've had the opportunity to build HTML5 apps, hybrid and native mobile apps, and progressive web apps and enjoyed working with, and managing, cross-functional agile teams. I love my work and take great satisfaction in solving problems with technology, writing clean code, holding to best practices, and seeking out productivity hacks.",
    },
  ],
  experience: [
    {
      role: "Lead Software Engineer",
      type: "Founder",
      thumbnail: "/images/clients/storydoc.png",
      company: "Storydoc",
      duration: "April 2021 - Present",
      tech: [
        "React",
        "Electron",
        "TypeScript",
        "NextJS",
        "TailwindCSS",
        "Dgraph",
        "Paddle",
        "Vercel",
        "GitHub",
      ],
      projects: ["Electron App", "POC Web App"],
      responsibilities: [
        "Designed and developed an offline-first Electron app to help authors write fiction.",
      ],
    },
    {
      role: "Senior Software Engineer",
      type: "Employee",
      thumbnail: "/images/clients/rs.png",
      company: "RS Components",
      duration: "May 2020 - April 2021 (1 year 1 month)",
      tech: [
        "React",
        "Styled Components",
        "NextJS",
        "NodeJS",
        "GraphQL",
        "Apollo",
        "Jest",
        "Cypress",
        "GitLab CI",
      ],
      projects: ["CMS Replacement Project", "Homepage Redesign"],
      responsibilities: [
        "Developed a redesigned homepage as a progressive web app powered by React (NextJS). ",
        "Contributed to an internal framework, based on NextJS, for bootstrapping micro-front-ends.",
        "Integrated homepage with a new set of CMS services backed by Contentful.",
      ],
    },
    {
      role: "Lead Software Engineer",
      type: "Founder",
      thumbnail: "/images/clients/storydoc.png",
      company: "Storydoc",
      duration: "Mar 2020 - May 2020 (3 months)",
      tech: [
        "React Native",
        "Styled Components",
        // "Jest",
        // "NodeJS",
        "GraphQL",
        "Apollo",
        "Neo4j",
        "Firebase",
        // "Google Cloud",
        "GitHub",
      ],
      projects: ["POC Web App", "POC Native App"],
      responsibilities: [
        "Designed and developed a React Native app that deploys as a PWA, Android app and iOS app.",
        "Implemented a GraphQL schema with Apollo Server to sit in front of our Neo4j database.",
        "Managed a cross-functional (part-time / volunteer) team of 2 x other engineers, 1 x designer.",
      ],
    },
    {
      role: "React / JS Consultant",
      type: "Contract",
      thumbnail: "/images/clients/rs.png",
      company: "RS Components",
      duration: "Jan 2019 - Mar 2020 (1 year 3 months)",
      tech: [
        "React",
        "CSS",
        "NextJS",
        "NodeJS",
        "GraphQL",
        "Apollo",
        "Jest",
        "TestCafe",
        "GitLab CI",
      ],
      projects: ["Checkout Redesign"],
      responsibilities: [
        "Developed the redesigned checkout UX as a micro-front-end application powered by NextJS.",
        "Collaborated with back-end engineers and consultants to design micro-service APIs.",
        "Designed and built a GraphQL aggregation service for basket and checkout apps.",
        "Developed inner-source modules to enable cong-driven, localised form validation.",
        "Up-skilled permanent members of staff new to the client's tech stack (React, NextJS, GraphQL).",
        "Participated in UI COP (community of practice) to teach and establish best practices.",
      ],
    },
    {
      role: "React / JS Consultant",
      type: "Contract",
      thumbnail: "/images/clients/rs.png",
      company: "RS Components",
      duration: "Jul 2018 - Dec 2018 (6 months)",
      tech: [
        "React",
        "CSS",
        "NextJS",
        "NodeJS",
        "GraphQL",
        "Apollo",
        "Jest",
        "GitLab CI",
      ],
      projects: ["B2B Internal App"],
      responsibilities: [
        "Rebuilt an internal admin tool as a new progressive web app powered by React (NextJS).",
        "Worked with back-end engineers and consultants to design micro-service APIs.",
        "Designed and implemented a GraphQL API to front the related REST services.",
      ],
    },
    {
      role: "Senior Frontend Software Engineer",
      type: "Employee",
      thumbnail: "/images/clients/ctm.png",
      company: "CompareTheMarket.com",
      duration: "Mar 2017 - Jul 2018 (1 year 5 months)",
      projects: [
        "React Component Library",
        "Redux Validation Framework",
        "Bike Insurance (Responsive UI)",
      ],
      tech: [
        "React",
        "Redux",
        "CSS",
        "SCSS",
        "NodeJS",
        "AWS",
        "Jest",
        "GitHub",
      ],
      responsibilities: [
        "Developed inner-source modules, leveraging ReactJS and Redux, to standardise UI components, make it easier to implement form validation logic and configure analytics across all CTM webapps.",
        "Collaborated with the UX team to develop a standardised component library / design system.",
        "Attended inceptions and steering meetings as a representative of the Front-end Platform team.",
        "Reviewed pull requests into our inner-source projects from colleagues around the business.",
        "Helped to upskill or onboard new developers (some new to the company, some to React).",
      ],
    },
    {
      role: "Frontend Software Engineer",
      type: "Employee",
      thumbnail: "/images/clients/ctm.png",
      company: "CompareTheMarket.com",
      duration: "Aug 2015 - Feb 2017 (1 year 7 months)",
      projects: [
        "Life Insurance (Responsive UI)",
        "Car Insurance (Responsive UI)",
        "Car Insurance (GDPR)",
      ],
      tech: [
        "HTML",
        "CSS",
        "jQuery",
        "KnockoutJS",
        "AngularJS",
        "React",
        "GitHub",
      ],
      responsibilities: [
        "Led the project team tasked with developing a responsive UI for the life insurance web app.",
        "Evangelised best practices in UI community meetings, especially OOCSS, ITCSS and BEM.",
        "Supported the migration of apps from jQuery and KnockoutKS to AngularJS.",
        "Trialed and then evangelised ReactJS (spoke and ran a workshop at Agile Peterborough).",
        "Redesigned the car insurance web app to be responsive (and compliant with EU regs).",
      ],
    },
    {
      role: "Frontend Software Engineer",
      type: "Employee",
      thumbnail: "/images/clients/e4e.png",
      company: "e4education",
      duration: "Nov 2012 - Jul 2015 (2 years 9 months)",
      tech: ["HTML", "CSS", "SCSS", "jQuery", "AngularJS", "Cordova", "GitHub"],
      responsibilities: [
        "Developed a new interface for the in-house CMS.",
        "Developed a hybrid mobile app using AngularJS and Cordova.",
        "Trained up a team of front-end web developers in HTML, CSS, jQuery.",
      ],
    },
    {
      role: "Junior Web Developer",
      type: "Employee",
      thumbnail: "/images/clients/e4e.png",
      company: "e4education",
      duration: "Nov 2011 - Nov 2012 (1 year 1 month)",
      tech: ["HTML", "CSS", "SCSS", "jQuery", "VBScript"],
      responsibilities: [
        "Sliced up 100s of photoshop designs and converted them to CMS powered websites.",
        "Developed a CSS framework to accelerate the build process for each site.",
      ],
    },
  ],
};
