import React from "react";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import Profile from "../../../components/profile";

import data from "../../../data/profile.edward";

const EdwardProfile = () => (
  <Layout>
    <SEO
      title={data.name}
      description={data.description}
      keywords={data.keywords}
    />
    <Profile {...data} />
  </Layout>
);

export default EdwardProfile;
